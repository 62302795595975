@keyframes shake {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(-5px);
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(213, 234, 255, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 48px 24px 36px;
  max-width: 360px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 24px;
  position: relative;

  &.limit-modal {
    padding: 48px 24px 24px;

    @media (max-height: 700px) {
      padding: 24px 16px;
    }

    @media (max-height: 450px) {
      padding: 16px;
    }
  }

  .button-close-popup {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 6px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-style: italic;
    color: #111;
    line-height: 1.45;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    font-style: italic;
    line-height: 1.5;
    text-align: center;
    color: #111;
  }

  .share-modal-text-1 {
    color: rgba(34, 34, 34, 0.7);
    margin-bottom: 48px;
  }
  .share-modal-text-2 {
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
  }

  .btns-container {
    justify-content: flex-start;
    max-width: calc(100% + 48px);
    overflow: auto;
    box-sizing: border-box;
    padding: 0 16px 12px;

    button {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 7px;
    }
  }

  .btn-facebook {
    background-image: url("/assets/images/facebook@3x.png");
  }

  .btn-snapchat {
    background-image: url("/assets/images/snapchat@3x.png");
  }

  .btn-instagram {
    background-image: url("/assets/images/insta@3x.png");
  }

  .btn-tiktok {
    background-image: url("/assets/images/tiktok.png");
  }

  .btn-whatsapp {
    background-image: url("/assets/images/whatsapp.png");
  }

  .btn-telegram {
    background-image: url("/assets/images/telegram@3x.png");
  }

  .btn-twitter {
    background-image: url("/assets/images/twitter@3x.png");
  }

  .limit-modal {
    &__text {
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      color: #fefee1;
      font-style: normal;
      margin-bottom: 24px;

      &.title {
        font-weight: 700;
        margin-bottom: 16px;
      }

      &.v2 {
        margin: 0;
        margin-top: 16px;
      }

      @media (max-height: 800px) {
        margin-bottom: 12px;

        &.title {
          margin-bottom: 8px;
        }

        &.v2 {
          margin-top: 8px;
        }
      }

      @media (max-height: 645px) {
        font-size: 14px;
      }
    }

    &__list-title {
      font-size: 24px;
      font-style: normal;
      margin-bottom: 16px;
      color: #fff066;

      @media (max-height: 800px) {
        margin-bottom: 8px;
      }

      @media (max-height: 645px) {
        font-size: 18px;
      }

      @media (max-height: 450px) {
        font-size: 16px;
      }
    }

    &__list {
      padding: 0 26px;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 1.5;
        color: #fff066;

        @media (max-height: 800px) {
          margin-bottom: 6px;
        }

        @media (max-height: 645px) {
          font-size: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: "";
          display: inline-block;
          width: 100%;
          max-width: 4px;
          height: 4px;
          margin-top: 12px;
          margin-right: 8px;
          border-radius: 50%;
          background: #fff066;
        }
      }
    }

    &__btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 24px;

      @media (max-height: 500px) {
        margin-top: 12px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 100%;
        max-width: 236px;
        font-weight: 700;
        font-size: 16px;
        color: #000;
        background: #fff066;
        border-radius: 30px;

        &.without-bg {
          background: none;
          color: #fefee1;
          text-transform: uppercase;
          margin-top: 24px;
          font-size: 14px;
          height: auto;

          @media (max-height: 800px) {
            margin-top: 12px;
          }
        }

        svg {
          margin-left: 8px;
          animation: shake 800ms infinite ease-out;
        }
      }
    }
  }
}

.copy-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;

  p {
    font-family: "Barbie";
    font-size: 24px;
    color: #ff62a5;
    line-height: 1;
    margin: 0;
  }

  button {
    width: 80px;
    height: 30px;
    border-radius: 30px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff62a5;
    margin-left: 24px;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }
  }
}
