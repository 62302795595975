$window-inner-height: var(--window-inner-height);

.main-page {
  // min-height: 100vh;
  box-sizing: border-box;
  padding: 60px 0 24px;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }

  // h1 {
  //     font-size: 24px;
  //     font-weight: 600;
  //     line-height: 1.67;
  //     text-align: center;
  // }

  p {
    line-height: 1.5;
    color: rgba(34, 34, 34, 0.7);
    margin-bottom: 30px;
    text-align: center;
    padding: 0;

    &.italic {
      font-style: italic;
    }
  }
}

.header {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  height: 30px;
}

.content-video {
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 32px;

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.btn-back {
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  background: none;

  svg {
    width: 18px;
    fill: #fff;
  }
}

.upload-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 24px;
}

.upload-content {
  flex-grow: 1;
  width: 100%;

  .container-v1 & {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  }

  .container-v2 & {
    padding-bottom: 64px;
  }
}

.upload-content-video {
  width: calc(100% + 32px);
  height: 280px;
  display: flex;
  justify-content: center;
  margin: 28px auto 0;
  margin-left: -16px;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.upload-notice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  .container-v1 & {
    margin-top: 24px;
  }

  .container-v2 & {
    margin-top: 20px;
  }
}

.upload-footer-text {
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: rgba(255, 255, 255, 0.32);
  margin-top: 16px;

  span {
    font-weight: bold;
  }
}

.upload-notice {
  font-size: 12px;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px 4px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.08);
  margin: 0 4px;

  img {
    width: 16px;
    margin-right: 6px;
  }
}

.upload-text {
  line-height: 1.5;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.upload-images-container {
  display: flex;
  justify-content: center;
  position: relative;

  .container-v1 & {
    align-items: center;
    flex-grow: 1;
  }
}

.upload-images {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.upload-image-container {
  width: 100%;
  position: relative;
  border-radius: 32px;
  border: dashed 1px rgba(255, 255, 255, 0.16);
  overflow: hidden;
  margin: 0 4px;
  background: transparent;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }

  svg {
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.upload-image-container-error {
  border-color: #ff5b3f;
}

.upload-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.results-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 24px;

  .item {
    cursor: pointer;
    width: 100%;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.08);
    background-image: linear-gradient(
      90deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0.04),
      rgba(217, 217, 217, 0)
    );
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;

    &.item--processed {
      animation: none;
    }

    &:after {
      content: "";
      display: block;
      padding-top: 140%;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }

  button {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 5;

    svg {
      width: 100%;
    }
  }
}

.results-grid .item--failed {
  background-color: rgba(255, 255, 255, 0.08);
  background-image: none;

  .item--failed_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  img {
    position: static;
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }

  p {
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 32px;
  }

  button {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #f8d34f;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    width: 112px;
    height: 32px;
    border-radius: 100px;
    background-color: rgba(248, 211, 79, 0.32);
  }
}

.results-grid-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: -16px;
  margin-bottom: 16px;
  width: calc(100% + 32px);
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

.btns-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-change {
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #f8d34f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  background-color: rgba(248, 211, 79, 0.32);
}

.btn-refresh {
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  border: none;
  background: #f8d34f;
  position: relative;

  svg {
    width: 24px;
    fill: #000;
    animation: shake 800ms infinite ease-out;
    position: absolute;
    top: 50%;
    right: 24px;
  }

  &--roll {
    svg {
      animation: spin-reverse 1.5s linear infinite;
    }
  }

  &.btn-refresh--wait {
    opacity: 0.32;
    color: #f8d34f;
    background: none;
  }
}

.btn-upload-foto {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #ff62a5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  background-color: transparent;
  border: 1px solid #ff62a5;
  position: relative;

  .icon-rocket {
    position: absolute;
    top: -10px;
    right: -4px;
    width: 53px;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d5eaff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  box-sizing: border-box;
}

.loader-cancel-button {
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 0;
  padding: 8px 16px;
  background: transparent;
  color: #888;
}

.spinner-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  &.isPro {
    position: absolute;
    top: calc(50% - 200px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.triple-spinner {
  display: block;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: solid 1px rgba(17, 17, 17, 0.09);

  &.isPro {
    width: 240px;
    height: 240px;
  }
}

.triple-spinner2 {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 74px;
  height: 74px;
  border-radius: 50%;
  border: solid 1px rgba(17, 17, 17, 0.09);

  &.isPro {
    width: 200px;
    height: 200px;
  }
}

.triple-spinner::before,
.triple-spinner2::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 1px solid transparent;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
}

.triple-spinner::before {
  border: 1px solid #ff62a5;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
  border: 1px solid #fa94d6;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin 3s linear infinite;
}

.triple-spinner-avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.triple-spinner-avatar-1 {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -5;
  overflow: hidden;

  &.isPro {
    width: 105px;
    height: 105px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.triple-spinner-avatar-2 {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  transform: translate(44%, 44%);
  z-index: -5;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.triple-spinner-icon {
  width: 32px;
}

.loader-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  margin-bottom: 24px;

  &.isPro {
    position: absolute;
    top: calc(50% + 80px);
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    font-style: italic;
    font-size: 14px;
    color: rgba(34, 34, 34, 0.7);
    max-width: 330px;
    text-align: center;
    line-height: 1.5;
  }

  @media (max-height: 620px) {
    margin-bottom: 16px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.error-page {
  color: rgba(34, 34, 34, 0.7);
  font-style: italic;
  height: var(--window-inner-height);
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    width: 180px;
    margin-bottom: 48px;
  }

  h1 {
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    max-width: 330px;
    line-height: 1.5;
    text-align: center;
  }

  .btn-upload-foto {
    color: #fff;
    background-color: #ff62a5;
  }
}

.error-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 24px;
}

.save-button {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  background-color: #f8d34f;

  span {
    display: block;
    transition: opacity 60ms;
  }

  svg {
    position: absolute;
    top: -9999px;
    left: -9999px;
    height: 24px;
    opacity: 0;
    transition: opacity 320ms;
  }

  &.pretend-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    border-radius: 50%;

    span {
      position: absolute;
      top: -9999px;
      left: -9999px;
      opacity: 0;
    }

    svg {
      width: 14px;
      position: static;
      opacity: 1;
    }
  }
}

.watermark-builder-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #000;

  header {
    width: 100%;
  }

  .btns-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 24px;
    margin-bottom: 40px;

    button {
      width: auto;
      flex: 1 0;
    }
  }

  .btn-share {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #f8d34f;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 56px;
    border-radius: 100px;
    background-color: transparent;
    border: solid 1px #f8d34f;

    svg {
      width: 68px;
      margin-right: 8px;
    }

    @media all and (max-width: 350px) {
      svg {
        width: 50px;
        margin-right: 6px;
      }
    }
  }

  .image-container {
    width: 90%;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }

  .hidden-image {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .result-image {
    position: absolute;
    z-index: 10;
    border-radius: 24px;
  }

  .watermark-holder {
    position: absolute;
    z-index: 11;
  }

  .watermark-tooltip {
    position: absolute;
    z-index: 15;
    top: 50%;
    right: 110%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: #fff;
      background-color: rgba(34, 34, 34, 0.6);
      border-radius: 3px;
      position: relative;
      white-space: nowrap;
      line-height: 1.25;
      padding: 8px;

      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 7px;
        border-color: transparent transparent transparent rgba(34, 34, 34, 0.6);
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
      }

      span {
        font-weight: 600;
      }
    }
  }
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  .btn-sort {
    width: 16px;
    height: 16px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;

    &:last-child {
      svg {
        width: 12px;
      }
    }

    &.active {
      svg {
        fill: #f8d34f;
      }
    }
  }

  svg {
    width: 100%;
    fill: rgba(255, 255, 255, 0.32);
  }
}

.loader-watermark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.pending-loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &.relative {
    position: relative;
  }
}

.pending-loader {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: solid 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.pending-loader-slider {
  border: solid 4px transparent;
  border-top: solid 4px #f8d34f;
  border-right: solid 4px #f8d34f;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .container {
    flex-grow: 1;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // position: fixed;
    // bottom: 10px;
    margin-top: 16px;

    svg {
      width: 56px;
    }

    p {
      font-size: 15px;
      font-weight: bold;
      line-height: 2.67;
      letter-spacing: 0.3px;
      text-align: center;
      color: #f8d34f;
      text-transform: uppercase;
      margin: 0;
    }
  }
}

.text-white {
  color: #fff;
}

.text-pink {
  color: #fd77ab;
}

.text-yellow {
  color: #fff066;
}

.text-underline {
  text-decoration: underline;
}

.upload-image-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.08);
  background-image: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0),
    rgba(217, 217, 217, 0.04),
    rgba(217, 217, 217, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

.error-text {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #ff5b3f;
  max-width: 360px;
  width: 100%;

  span {
    font-weight: bold;
  }
}

.results-grid-2 {
  position: relative;
  max-height: calc(100vh - 85px);
  overflow: hidden;
  width: calc(100% + 32px);
  padding: 0 18px;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-left: -16px;

  .item {
    background-image: linear-gradient(
      90deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0.3),
      rgba(217, 217, 217, 0)
    );
    background-size: 86px 100%;
  }
}

.subscribe-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(180, 215, 254);
  border-radius: 24px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  padding: 16px 20px 56px;

  h2 {
    font-size: 24px;
    line-height: 1.34;
    color: #000;
    position: relative;
    z-index: 16;
    max-width: 290px;
    margin: 0 auto 16px;

    svg {
      width: 32px;
      position: absolute;
      left: -20px;
      top: -20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    position: relative;
    z-index: 16;
    max-width: 350px;
    margin: 0 auto;

    svg {
      position: absolute;
      right: 0;
      top: calc(100% - 12px);
      width: 32px;
    }
  }

  .text-terms {
    position: absolute;
    bottom: 16px;
    font-size: 75%;
  }

  .subscribe-btn {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-transform: uppercase;
    width: 100%;
    height: 56px;
    border-radius: 100px;
    background-color: #f8d34f;
    position: relative;
    right: auto;

    svg {
      fill: #000;
      width: 25px;
      margin-left: 16px;
      animation: shake 800ms infinite ease-out;
      position: absolute;
      top: 50%;
      right: 24px;
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(-5px) translateY(-50%);
    }

    50% {
      transform: translateX(5px) translateY(-50%);
    }

    100% {
      transform: translateX(-5px) translateY(-50%);
    }
  }

  .price-text {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: rgba(255, 255, 255, 0.32);
    margin-top: 8px;
    margin-bottom: 24px;

    span {
      text-transform: uppercase;
    }
  }

  &.purchase-banner {
    padding-top: 38px;

    h2 {
      max-width: max-content;
      margin-bottom: 8px;

      svg {
        left: -36px;
        top: -16px;
      }
    }

    p {
      margin-bottom: 24px;
    }

    .subscribe-btn {
      max-width: 240px;
      margin: 0 auto;
    }
  }

  &.v2 {
    justify-content: space-between;
    padding: 10% 0 16px;
    background: #ff62a5;
    overflow: visible;

    h2 {
      font-size: 24px;
      margin: 0 40px 12px;
      color: #fff;
    }

    p {
      &.text-white {
        color: rgb(254, 254, 225);
        margin: 12px 24px 0;
        font-size: 18px;
        line-height: 24px;
      }

      &.text-yellow {
        color: #fff066;
        margin: 0 24px;
        font-size: 12px;
        line-height: 1.5;
      }
    }

    @media (max-height: 480px) {
      padding: 5% 0 16px;

      h2 {
        font-size: 20px;
      }

      p {
        &.text-white {
          font-size: 14px;
          line-height: 18px;
        }

        &.text-yellow {
          color: #fff066;
          margin: 0 24px;
          font-size: 12px;
          line-height: 1;
        }
      }
    }

    .text-terms {
      position: absolute;
      bottom: -25px;
      font-size: 12px;
      color: #000;
    }
  }

  &.v3 {
    p {
      &.text-white {
        margin-bottom: 10px;
      }
    }
  }
}

.subscribe-text {
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2px;

  span {
    text-decoration: underline;
  }
}

.subscribe-footer-container {
  display: flex;
  flex-direction: column;
}

.subscribe-footer-container-hide {
  visibility: hidden;
}

.subscribe-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.32);
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    padding: 4px;
    margin: 0 3px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -3px;
      display: block;
      height: 12px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.32);
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.text-bold {
  font-weight: bold;
}

.text-yellow {
  color: #f8d34f;
}

.text-pink {
  color: #ff62a5;
}

.upload-image-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  svg {
    width: 40px;
    position: static;
    transform: none;
  }

  p {
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: rgba(255, 255, 255, 0.32);
    margin-top: 8px;
  }
}

.-show-popup {
  overflow: hidden;
}

.result-page {
  height: var(--window-inner-height);
  display: flex;
  flex-direction: column;

  .container {
    height: var(--window-inner-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-x: hidden;
    padding: 16px 0;

    @media (min-width: 1025px) {
      padding: 84px 0 36px;
    }

    @media (max-width: 991px) {
      padding: 0 0 16px;
    }
  }

  .creative-download {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background-color: #ff62a5;
    transition: opacity 1s;
    border: 1.5px solid #ff62a5;

    &[disabled] {
      opacity: 0.25;
    }
  }

  .btns-container {
    max-width: 390px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    button {
      font-weight: bold;
      height: 48px;
      width: auto;
      max-width: initial;
      flex: 1 0;
      box-sizing: border-box;
      border-radius: 24px;
      padding: 0;

      &:first-child {
        margin-right: 12px;
      }
    }
  }
}

.tab-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  overflow: auto;
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
  padding-top: 2px;
  padding-left: 0;
  padding-right: 10px;
  // padding-bottom: 10px;
  margin: 0 auto 5px;
  height: 100px;

  @media (min-width: 1025px) {
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #3b91f2;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media all and (max-width: 560px) {
    max-width: 100%;
  }
}

.btn-choice-tab {
  width: 75px;
  height: 75px;
  border-radius: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  font-size: 8px;
  text-align: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
  background-size: cover;
  padding: 0 6px 4px;
  margin-left: 6px;
  transition: all 0.15s linear;

  &.active {
    box-shadow: inset 0 0 0 3px rgb(255, 98, 165);
    transform: translateY(-3px);
    height: 83px;
    width: 83px;
    z-index: -1;
  }

  &:first-child {
    margin-left: 15px;
  }

  &.error {
    background-color: #92c9ff;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
    }
  }

  &.waiting {
    .timer-loader {
      z-index: 10;
    }
  }
}

.creative-block {
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: relative;
  max-width: 360px;
  width: 100%;
  border-radius: 24px;
  margin: 0 auto 15px;
  overflow: hidden;

  @media (max-width: 991px) {
    margin-bottom: 6px;
  }

  @media all and (max-width: 540px) {
    border-radius: 0;
    max-width: 100%;
  }
}

.creative-holder {
  position: relative;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  max-width: 694px;
  background-color: #92c9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto 0;

  .creative {
    height: 100%;
    width: 100%;

    img {
      border-radius: 2px;
      margin: 0 auto;
      display: block;
      object-fit: contain;
      pointer-events: none;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  .original {
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    padding: 4px;
    background: #fff;
    transform: rotate(-3deg);
    animation: bounce 0.2s;

    img {
      pointer-events: none;
      display: block;
      width: 100%;
      height: auto;
      border-radius: 5px;
    }

    button {
      box-sizing: border-box;
      background: none;
      position: absolute;
      top: -15%;
      right: -15%;
      width: 30%;
      height: 30%;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__show-btn {
      font-family: "Helvetica", sans-serif;
      position: absolute;
      left: 15px;
      bottom: 15px;
      height: 32px;
      width: 100%;
      max-width: 108px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      line-height: 1;
      animation: fadein 0.3s;

      svg {
        height: 16px;
        width: 16px;
        margin-right: 6px;
        margin-top: -3px;
      }
    }
  }
}

/*loader roller*/
$loader-duration: 1.2s;
$loader-iteration-count: infinite;
$loader-direction: normal;

.loader-roller-result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(213, 234, 255, 0.75);
  backdrop-filter: blur(10px);
  z-index: 150;
}

.loader-roller-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 50px;
}

.loader-roller {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  &:after {
    content: "";
    top: auto;
    position: absolute;
    display: block;
    animation: shadow $loader-duration $loader-iteration-count linear;
    bottom: 0em;
    left: 0;
    height: 4px;
    width: 12px;
    border-radius: 50%;
    background-color: #000;
  }

  .roller {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    animation-iteration-count: $loader-iteration-count;
    animation-timing-function: linear;
    animation-duration: $loader-duration;
  }

  .roller:first-child {
    left: 0;
    animation-name: rollercoaster;
    transform: rotate(135deg);
  }

  .roller:last-child {
    right: 0;
    animation-name: rollercoaster2;
    transform: rotate(-45deg);
  }

  .roller:before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #3b91f2;
    border-radius: 50%;
  }
}

.loader-roller2 {
  &:after {
    animation-delay: 0.15s;
  }

  .roller {
    animation-delay: 0.15s;
  }
}

.loader-roller3 {
  &:after {
    animation-delay: 0.3s;
  }

  .roller {
    animation-delay: 0.3s;
  }
}

@keyframes rollercoaster {
  0% {
    transform: rotate(135deg);
  }

  8% {
    transform: rotate(240deg);
  }

  20% {
    transform: rotate(300deg);
  }

  40% {
    transform: rotate(380deg);
  }

  45% {
    transform: rotate(440deg);
  }

  50% {
    transform: rotate(495deg);
    opacity: 1;
  }

  50.1% {
    transform: rotate(495deg);
    opacity: 0;
  }

  100% {
    transform: rotate(495deg);
    opacity: 0;
  }
}

@keyframes rollercoaster2 {
  0% {
    opacity: 0;
  }

  49.9% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: rotate(-45deg);
  }

  58% {
    transform: rotate(-160deg);
  }

  70% {
    transform: rotate(-240deg);
  }

  80% {
    transform: rotate(-300deg);
  }

  90% {
    transform: rotate(-340deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes shadow {
  0% {
    opacity: 0.3;
    transform: translateX(35px) scale(0.25, 0.25);
  }

  8% {
    transform: translateX(9px) scale(1, 1);
  }

  20% {
    transform: translateX(0px) scale(0.6, 0.6);
  }

  40% {
    transform: translateX(-5px) scale(0.25, 0.25);
    opacity: 0.1;
  }

  50% {
    transform: translateX(32px) scale(0.6, 0.6);
    opacity: 0.3;
  }

  60% {
    transform: translateX(70px) scale(1, 1);
    opacity: 0.05;
  }

  65% {
    transform: translateX(78px) scale(0.6, 0.6);
  }

  80% {
    transform: translateX(65px) scale(0.25, 0.25);
    opacity: 0.1;
  }

  90% {
    transform: translateX(43px) scale(0.4, 0.4);
  }

  100% {
    transform: translateX(32px);
    opacity: 0.3;
  }
}

.controls-view-container {
  padding: 0 15px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto 42px;
  transition: opacity 1s;

  &.hidden {
    visibility: hidden;
  }

  &.disabled {
    opacity: 0.25;
  }
}

.controls-view {
  display: flex;
  align-items: center;
  font-size: 9px;

  &:first-child {
    margin-bottom: 28px;
  }

  p {
    color: rgba(17, 17, 17, 0.6);
    width: 97px;
    box-sizing: border-box;
    padding-right: 7px;
  }
}

.controls-view-btns {
  display: flex;
  align-items: center;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    color: #111;
    width: 33%;
  }

  input {
    position: absolute;
    top: -9999px;
    left: -9999px;
    appearance: none;
    opacity: 0;

    &:checked + label {
      &:after {
        box-sizing: border-box;
        background-color: #fff;
        border-width: 6px;
      }
    }
  }

  label {
    position: relative;
    box-sizing: border-box;
    padding-left: 27px;

    &:after {
      box-sizing: border-box;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: 0 solid rgb(103, 172, 250);
      background-color: rgb(180, 215, 254);
      transition: all 240ms;
    }
  }
}

.creative-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    max-width: 270px;
    color: rgba(34, 34, 34, 0.7);
    line-height: 24px;
  }

  button {
    width: 174px;
    height: 48px;
    border: 1px solid #3b91f2;
    border-radius: 24px;
    background: none;
    color: #3b91f2;
    font-weight: bold;
    margin-top: 48px;
  }
}

.controls-view-container-2 {
  display: flex;
  align-items: center;
  // gap: 4px;
  padding: 0 15px;
  width: 100%;
  max-width: 390px;
  margin: 0 auto 18px;
  transition: opacity 1s;
  z-index: 10;

  &.hidden {
    visibility: hidden;
  }

  &.disabled {
    opacity: 0.25;
  }

  .view-btn {
    display: flex;
    align-items: center;
    flex: 1 0;
    background-color: rgb(103, 172, 250);
    border-radius: 8px;
    position: relative;
    height: 32px;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 6px;

    &:first-child {
      margin-right: 4px;
    }
  }

  .view-btn {
    display: flex;
    align-items: center;
    flex: 1 0;
    background-color: rgb(103, 172, 250);
    border-radius: 8px;
    position: relative;
    height: 32px;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 6px;
  }

  .view-btn-arrow {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;

    &.view-btn-arrow-rotate {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .view-btn-title {
    font-size: 5px;
    color: #d5eaff;
    text-transform: uppercase;
    line-height: 6px;
    position: absolute;
    left: 12px;
    top: 4px;
  }

  .view-btn-value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: none;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    color: #fff;
    text-transform: capitalize;
    text-align: left;
    padding-left: 12px;

    span {
      font-weight: 400;
      color: #d5eaff;
    }
  }

  ul {
    position: absolute;
    left: 0;
    bottom: calc(100% + 3px);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  li {
    background-color: #b4d7fe;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    overflow: hidden;

    button {
      width: 100%;
      height: 100%;
      background: none;
      padding: 0 12px;
      box-sizing: border-box;
      text-align: left;
      text-transform: capitalize;
      font-size: 12px;
    }
  }
}

.timer-loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -10;
  margin: 0;
}

.timer-loader .rotate {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 10;
}

.timer-loader .rotate path {
  fill: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

@media all and (min-width: 560px) and (max-width: 1030px) {
  .result-page .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .tab-container,
  .creative-block {
    max-width: 100%;
  }

  .controls-view-container-2,
  .result-page .btns-container {
    max-width: 100%;
    padding: 0;
  }
}

.swiper {
  padding-bottom: 30px;
  &-slide {
    // height: calc(100% + 30px);
  }
}
