.photochooser-screen {
  box-sizing: border-box;
  padding-bottom: 260px;

  .header {
    margin-bottom: 24px;
  }

  .images-grid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    .btn-upload {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.33;
      color: #999;

      span {
        font-weight: 600;
      }
    }

    .btn-select-delete {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      line-height: 1.33;
      background: none;
    }

    .error-text {
      font-family: 'Poppins', sans-serif;
      font-size: 8px;
      font-weight: 300;
      line-height: 1.5;
      color: #e92f35;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      transform: none;
    }
  }

  .images-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;

    .image-item {
      position: relative;

      &--pending {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
          background-color: rgba(255, 255, 255, 0.45);
        }
      }

      &--valid {
        background: rgb(182, 203, 174);
      }

      &--invalid {
        border: solid 2px #99292d;

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(153, 41, 45, 0.45);
        }

        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }
      }

      .holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          pointer-events: none;
          transition: opacity 240ms;
        }
      }

      .status {
        display: block;
      }

      input {
        position: absolute;
        width: 0;
        height: 0;
        z-index: -20;

        &:checked {
          & + label {
            background-color: #f8d34f;

            svg {
              display: block;
            }
          }
        }
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 8px;
        right: 8px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        border: solid 1px #fff;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 20;

        svg {
          display: none;
          width: 8px;
          height: 8px;
          fill: #000;
        }
      }
    }
  }


  .images-grid-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }

  .image-item,
  .btn-more {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    &:after {
      content: '';
      width: 100%;
      padding-top: 100%;
      display: block;
    }
  }

  .btn-more {
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    font-weight: 500;
    text-align: center;
    color: #000;
    display: flex;
    background-color: #f8d34f;
    position: relative;

    img {
      width: 24px;
      margin-bottom: 8px;
    }
  }

  .btn-more-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list-conditions {
    //flex-grow: 1;
    padding: 0 24px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    img {
      width: 24px;
      margin-right: 40px;
    }

    p {
      font-weight: 300;
      color: #fff;
      line-height: 1.25;
    }

    span {
      font-weight: 500;
    }
  }

  &--has-photos {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;

    .btn-container {
      .btn {
        color: #19161f;
        background-color: #f8d34f;
      }
    }
  }

  &--no-photos {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;

    .btn-container {
      .btn {
        color: #19161f;
        background-color: #f8d34f;
      }
    }
  }

  .btns-option-container {
    display: flex;
    align-items: center;
  }

  .btn-option {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    line-height: 1.33;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    background: none;

    img {
      width: 10px;
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 24px;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    background-color: #000;
    z-index: 100;

    &:after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      left: -24px;
      width: calc(100% + 48px);
      height: 160px;
      background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: -24px;
      width: calc(100% + 48px);
      height: 60px;
      background-color: #000;
    }
  }

  .btn-proceed {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 240px;
    height: 56px;
    border-radius: 100px;
    background-color: #f8d34f;
  }
}