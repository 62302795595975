.button {
  box-sizing: border-box;
  position: relative;
  min-width: 240px;
  height: 56px;
  padding: 0 24px;
  border-radius: 100px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.32px;

  img {
    height: 48px;
    width: 48px;
    object-fit: contain;
    position: absolute;
    right: -4px;
    top: -10px;
  }

  .image-bottom {
    width: 80px;
    top: auto;
    left: auto;
    right: -20;
    bottom: -12px;
  }

  .image-top {
    top: -24px;
    left: 6px;
  }

  &[disabled] {
    color: rgb(255 255 255 / 45%);
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.button--white {
  background-color: #fff;
}